.footer_division {
  @apply bg-white w-full max-w-[937px] h-[1px] mb-[70px] mx-auto;
}

.footer_section {
  @apply flex flex-col md:flex-row justify-center md:h-[350px] w-full backdrop-blur-[17px] md:gap-[200px];
}

.social_link_wrapper {
  @apply flex flex-wrap w-[80%] mx-auto md:w-1/2 md:flex-col justify-around md:justify-between pb-[10%] md:pl-[78px];
}

.company_log {
  @apply flex items-center gap-[2px] mb-[35px] cursor-pointer;
}

.social_links {
  @apply flex flex-wrap gap-[22px];
}

.link {
  @apply flex w-4 h-4 cursor-pointer;
}

.social_link_wrapper span {
  @apply text-[#4a4a4e] font-inter text-[11px] leading-[28px];
}

.company_info {
  @apply flex justify-center gap-[112px] mx-auto md:mt-0 w-[80%] md:w-1/2;
}

.section_title {
  @apply text-[#1c1725] font-inter text-base font-semibold leading-[28px];
}

.section {
  @apply flex flex-col gap-[13px] font-inter text-[#0a142f] text-base mt-[22px];
}

.section_item {
    @apply font-inter text-[#4a4a4e] text-sm cursor-pointer w-[188px];
}
