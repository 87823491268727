.App {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.container {
  max-width: 1260px;
  margin: 0 auto;
}

.background {
  position: absolute;
  width: 100%;
  height: 60%;
  top: 0;
  min-height: 2300px;
  background: linear-gradient(190deg, rgba(183, 191, 252, 0.7) -19.32%, rgba(245, 245, 247, 0) 93.29%);
  z-index: -2;
}

.background-color {
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0px;
  background: linear-gradient(7deg, rgba(197, 202, 252, 0.8) 8.95%, rgba(255, 255, 255, 0) 91.31%, rgba(255, 255, 255, 0) 91.31%);
  z-index: -2;
}