.welcome_container {
  @apply sm:flex w-full pt-[106px];
}

.welcome_content {
  @apply relative sm:w-1/2 pt-10 sm:pt-[120px] sm:pl-[75px] font-inter;
}

.welcome_img {
  @apply relative sm:w-1/2;
  background-image: url("../../assets/rocket.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position-x: 50%;
  aspect-ratio: 942/1350;
  z-index: 1;
}

.star_first {
  @apply absolute left-[-80px] top-[60px] w-[73px];
}

.star_second {
  @apply absolute left-[-50px] top-[35%] w-[140px];
}

.cloud_first {
  @apply absolute bottom-[-175px] left-[-185px];
}

.cloud_second {
  @apply absolute w-[456px] rotate-[6deg] bottom-[-155px] right-[-70px];
}

.cloud_third {
  @apply absolute bottom-[-195px] right-[190px] z-[1] w-[340px] rotate-[1deg];
}

@media (max-width: 1024px) {
  .welcome_img {
    background-size: 76%;
    background-position-x: 0%;
  }

  .cloud_first {
    width: calc(29.5898vw);
    bottom: calc(-5.37109vw);
    left: calc(-10.7422vw);
  }

  .cloud_second {
    width: calc(31.6406vw);
    bottom: calc(-8.78906vw);
    right: calc(4.39453vw);
  }

  .cloud_third {
    width: calc(19.127vw);
    bottom: calc(-4.88281vw);
    right: calc(21.4844vw);
  }
}

@media (max-width: 768px) {
  .welcome_img {
    background-size: 50%;
    background-position-x: 50%;
    height: 100vw;
    width: 100%;
  }

  .cloud_first {
    display: none;
  }

  .cloud_second {
    width: calc(44.2708vw);
    bottom: calc(-5.20833vw);
    left: calc(11.7188vw);
  }

  .cloud_third {
    width: calc(44.2708vw);
    bottom: calc(-3.90625vw);
    right: calc(11.7188vw);
  }
}

.effect_1 {
  @apply absolute top-[30px] left-[-150px] rounded-[318px] opacity-[0.59] w-[318px] h-[318px] blur-[150px] z-[-1];
  background: linear-gradient(
    rgb(255, 245, 0) 0%,
    rgba(255, 247, 61, 0.84) 100%
  );
}

.effect_2 {
  @apply absolute left-[-200px] bottom-[-250px] rounded-[440px] opacity-[0.4] w-[440px] h-[440px] blur-[150px] z-[-1];
  background: linear-gradient(
    rgb(0, 255, 194) 0%,
    rgba(65, 61, 255, 0.84) 100%
  );
}

.effect_3 {
  @apply absolute right-[-60px] bottom-0 opacity-[0.59] w-[219px] h-[219px] blur-[150px] z-[-1];
  background: linear-gradient(
    rgb(255, 0, 199) 0%,
    rgba(255, 61, 166, 0.84) 100%
  );
}

.effect_4 {
  @apply absolute right-[-60px] top-[90px] opacity-[0.59] w-[219px] h-[219px] blur-[150px] z-[-1];
  background: linear-gradient(
    rgb(0, 56, 255) 0%,
    rgba(61, 255, 243, 0.84) 100%
  );
}

.effect_5 {
  @apply absolute left-0 top-0 opacity-[0.5] w-full h-full blur-[103.25px] z-[-1] bg-white;
}

.welcome_text_style_1 {
  @apply text-[#0f172a] text-[45px] text-center sm:text-start md:text-[51px] lg:text-[64px] font-extrabold m-0 sm:leading-[70px];
}

.welcome_text_style_2 {
  @apply text-[80px] md:text-[120px] text-center sm:text-start lg:text-[128px] font-extrabold my-[5px] leading-[70px] sm:leading-[105px];
  background: linear-gradient(
      90deg,
      rgb(255, 97, 249) -0.55%,
      rgb(255, 0, 245) 57.66%
    )
    text;
  -webkit-text-fill-color: transparent;
}

.welcome_text_content {
  @apply text-black text-[20px] text-center mx-auto sm:ml-0 sm:text-start font-[400] max-w-[80%] mt-[15px] mb-[14px] leading-[125%];
}

.external_icons {
  @apply flex gap-[25px] flex-wrap justify-center sm:justify-start;
}

.social_icon {
  @apply flex items-center justify-center cursor-pointer w-[40px] h-[40px] border border-black rounded-[50%] stroke-[#0a142f] stroke-[2px];

  img {
    @apply w-[40%] h-[40%] text-center;
  }
}
