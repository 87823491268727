.convenience_wrapper {
  @apply py-[130px];
}

.Convenience_title {
  @apply text-[#415162] w-full text-center font-inter font-bold text-[43px] leading-[45px] mt-[70px] mb-[45px];
}

.content {
  @apply grid w-full gap-[19px];
  grid-template-columns: 248.56fr 282.3fr 260.73fr 207.41fr;
}

@media (max-width: 1024px) {
  .content {
    grid-template-columns: 333fr 343fr;
    grid-template-rows: 414fr 420fr;
  }
}

@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 70%;
    margin: 0 auto;
  }

  .card_row {
    flex-direction: column;

    img {
      width: auto;
      top: auto;
      right: auto;
      left: auto;
    }
  }
}

.card_col {
  @apply relative rounded-[40px] border border-white/40 bg-white/40 backdrop-blur-[12.5px] pt-[29px] pr-5 pb-[35px] pl-[30px];
}

.card_row {
  @apply relative flex gap-2 items-center rounded-[40px] border border-white/40 bg-white/40 backdrop-blur-[12.5px] pt-[21px] pr-6 pb-[17px] pl-[17px];
}

.card_title {
  @apply text-2xl font-inter font-semibold text-[#415162] mt-0;
}

.card_row .card_title {
  @apply mb-[5px];
}

.card_col .card_title {
  @apply mb-[18px];
}

.card_text {
  @apply text-[15px] font-inter text-[#415162] leading-[18px];
}
