.top_menu_container {
    @apply fixed max-w-[1260px] w-[96%] ml-[2%] py-5 z-[10] font-gotham;
}

.top_menu_bar {
    @apply flex items-center bg-white/50 border border-white/30 justify-between px-[22px] py-[13px] rounded-[50px] backdrop-blur-[6px] box-border;
}

.top_menu {
    @apply hidden md:flex items-center gap-1 box-border ml-[90px];
}

.menu_item {
    @apply text-sm px-4 py-2 cursor-pointer rounded-[50px] transition-all duration-300;
}

.focused {
    @apply bg-white;
}

.menu_item:hover {
    @apply bg-white;
}

.menu_btn_grp {
    @apply flex items-center gap-2 box-border;
}

.menu_wallet_btn {
    @apply text-white text-base font-semibold bg-[#00adee] rounded-[50px] px-3 py-[7px];
}

.burger_icon {
    @apply md:hidden cursor-pointer;
}

.dropdown {
    border-radius: 15px;
    opacity: 0.98;
    background: rgba(168, 172, 201, 0.8);
    gap: 16px;
    padding: 16px 4px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    position: fixed;
    width: 100%;
    top: 0px;
    right: 0px;
}

.dropdown_item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(2px);
    font-size: 13px;
    border-radius: 50px;
}