.subscribe_container {
    @apply flex flex-col md:flex-row justify-center relative px-[44px] justify-end pb-[52px] gap-[71px];
}

.subscribe_left {
    @apply flex flex-col justify-center max-w-[464px] mx-auto;
}

.newsletter {
    @apply text-center font-inter text-4xl font-semibold leading-[28px] my-[30px];
    background: linear-gradient(90deg, rgb(255, 97, 249) 0.07%, rgb(255, 0, 245) 86.08%) text;
    -webkit-text-fill-color: transparent;
}

.newsletter_input {
    @apply w-full h-[40px] px-[25px] mb-4 rounded-[30px] border border-black/10 bg-white/25 font-inter text-base;
}

.newsletter_button {
    @apply w-[385px] h-[40px] rounded-[80px] backdrop-blur-[2px] text-white text-center font-inter text-base font-bold leading-[20px];
    background: linear-gradient(99deg, rgb(229, 135, 231) -8.37%, rgb(92, 108, 246) 77.41%);
}

.newsletter_confirm_label {
    @apply relative flex gap-[2px] flex-wrap pl-[25px] mb-3 cursor-pointer text-[#252525] text-[9px] leading-[19px];
}

.newsletter_confirm_label input[type='checkbox'] {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

.newsletter_confirm_label span {
    @apply absolute top-[2px] left-0 h-[14px] w-[14px] rounded-[3px] bg-[#e8e9fb] border-[#d2d3e3];
}

.newsletter_confirm_label span::after {
    display: block;
    left: 4px;
    top: 2px;
    width: 3px;
    height: 6px;
    border-style: solid;
    border-color: rgb(182, 183, 196);
    border-image: initial;
    border-width: 0px 3px 3px 0px;
    transform: rotate(45deg);
}

.newsletter_confirm_label input:checked ~ span::after {
    display: block;
}

.privacy_policy {
    @apply my-0 text-[#4388dd] font-inter text-[9px] leading-[19px] underline;
}