.tokenomics_title {
  @apply text-[32px] font-inter text-[#415162] font-semibold text-center leading-[115.6%] my-8;
}

.tokenomics_description {
  @apply flex text-[#5c5f67] text-center font-inter text-xl mx-auto w-[75%];
}

.tokenomics_container {
  @apply relative mt-[130px] mb-[220px] w-full mx-auto max-w-[617px];
  background: url("../../assets/tokenomic.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  aspect-ratio: 1/1;
}

.token_item_wrapper {
  @apply absolute flex rounded-[61.5px] border-2 border-white backdrop-blur-[9px];
}

@media (max-width: 1200px) {
    .tokenomics_container {
        transform: scale(0.8);
    }
}

@media (max-width: 1024px) {
  .tokenomics_container {
    background: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 60px;
    transform: none;
  }

  .token_item_wrapper {
    position: unset;
    transform: none !important;
  }
}

.token_progress {
  @apply flex rounded-[50%] backdrop-blur-[9px] border-2 border-white justify-center items-center text-base font-semibold;
}

.token_name {
  @apply font-inter font-semibold tracking-[-0.32px] border-white whitespace-nowrap;
}

.token_counts {
  @apply flex flex-col justify-center ml-[30px] text-sm leading-[22px] text-start;
}

.liquidity_wrapper {
  @apply flex-row-reverse px-3 py-[6px] right-[75%] top-[3%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.liquidity_name {
  @apply text-base text-[#f1aa1d] leading-[70px] pr-[11px] pl-[41px] mr-[10px] border-r-[1px];
}

.liquidity_progress {
  @apply text-[#f1aa1d] w-[77px] h-[77px];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.private_wrapper {
  @apply px-[10px] py-[7px] left-[75%] top-[1.7%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.private_name {
  @apply text-xl text-[#ca49c7] leading-[75px] pl-[11px] pr-[41px] ml-[10px] border-l-[1px];
}

.private_progress {
  @apply text-base text-[#ca49c7] w-[77px] h-[77px];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.marketing_wrapper {
  @apply flex-row-reverse px-3 py-[6px] right-[88.3%] top-[21.3%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.marketing_name {
  @apply text-base text-[#0ac08f] leading-[60px] pl-[52px] pr-[11px] mr-[10px] border-r-[1px];
}

.marketing_progress {
  @apply text-base text-[#0ac08f] w-[60px] h-[60px];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.public_sale1_wrapper {
  @apply px-2 py-[6px] left-[91%] top-[21.3%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.public_sale1_name {
  @apply text-base text-[#aa6db0] leading-[60px] pl-[9px] pr-[28px] ml-[10px] border-l-[1px];
}

.public_sale1_progress {
  @apply w-[60px] h-[60px] text-[#aa6db0] text-[13px];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.airdrops_wrapper {
  @apply flex-row-reverse px-[7px] py-1 right-[91%] top-[67.1%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.airdrops_name {
  @apply text-base text-[#22afe7] leading-[60px] pr-[11px] pl-[49px] mr-[10px] border-r-[1px];
}

.airdrops_progress {
  @apply w-[60px] h-[60px] text-[#22afe7] text-base;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.public_sale2_wrapper {
  @apply px-[14px] py-[11px] left-[91%] top-[67.1%] translate-x-[-20px] translate-y-[35%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.public_sale2_name {
  @apply text-[15px] text-[#49b88f] leading-[60px] pl-[10px] pr-[54px] ml-[10px] border-l-[1px];
}

.public_sale2_progress {
  @apply w-[60px] h-[60px] text-[#49b88f] text-[13px];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.team_wrapper {
  @apply flex-row-reverse px-[7px] py-[6px] top-[91%] right-[67.1%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.team_name {
  @apply text-xl text-[#27c2a5] leading-[70px] pr-[14px] pl-[74px] mr-[10px] border-r-[1px];
}

.team_progress {
  @apply w-[76px] h-[76px] text-[#27c2a5] text-base;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.advisors_wrapper {
  @apply px-[10px] py-[5px] top-[91%] left-[67.1%];
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}

.advisors_name {
  @apply text-base text-[#23b256] leading-[70px] pl-[10px] pr-[28px] ml-[10px] border-l-[1px];
}

.advisors_progress {
  @apply w-[76px] h-[76px] text-[#23b256] text-base;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.89) 5.65%,
    rgba(255, 255, 255, 0.49) 94.35%
  );
}
