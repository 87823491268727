.title {
  @apply text-[#415162] w-full text-center font-inter font-bold text-[43px] leading-[45px] mt-[70px] mb-[45px];
}

.secure_card {
  @apply relative w-[399px] h-[324px] rounded-[40px] backdrop-blur-[12.5px] border border-white/40 bg-[#0e87f836];
  box-shadow: rgba(0, 0, 0, 0.15) -10px 30px 71.5px 10px;
}

.transparent_card {
  @apply relative rounded-[40px] border-white/40 bg-white/40 backdrop-blur-[12.5px] w-[399px] h-[324px] lg:h-[428px] lg:w-[399px] mx-[-38px] z-[2];
  box-shadow: rgba(0, 0, 0, 0.15) -10px 30px 71.5px 10px;
}

.tailored_card {
  @apply relative rounded-[40px] border border-white/40 bg-[#ff32f738] backdrop-blur-[12.5px] w-[399px] h-[324px];
  box-shadow: rgba(0, 0, 0, 0.15) -10px 30px 71.5px 10px;
}

.card_title {
  @apply text-[#415162] text-center font-inter font-semibold text-[32px] leading-[125%] tracking-normal whitespace-nowrap my-[26.5px];
}

.card_content_text {
  @apply text-center text-[#415162] font-inter text-xl tracking-normal leading-6;
}

.card_icon_img {
  @apply absolute bottom-[25px] left-[45%] translate-x-[-50%] translate-y-[60%];
}
