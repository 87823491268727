.divide_line {
    @apply bg-[#c3c3c3] w-[80%] h-[1px] mb-[72px] ml-[10%];
}

.sponsor_wrapper {
    @apply grid w-full pb-[147px] gap-[98px] items-center;
    grid-template-columns: 1fr 2.75fr 3.57fr 2.68fr 1.2fr;
}

@media (max-width: 1240px) {
    .sponsor_wrapper {
        width: 70%;
        margin: 0 auto;
        gap: 40px;
        grid-template-columns: 1fr 2.75fr 3.57fr;
    }
}