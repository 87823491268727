.team_title {
    @apply text-[#415162] text-center font-inter text-[43px] font-semibold my-[30px];
}

.team_cards_wrapper {
    @apply flex flex-wrap justify-center rounded-[50%] items-start gap-[33px];
}

.team_card_img {
    @apply flex items-center justify-center rounded-[40px] bg-[#f1f1f1] w-[215px] h-[215px];
    box-shadow: rgba(0, 0, 0, 0.15) 0px 16px 31px 0px;
}

.team_card_text {
    @apply flex gap-[5px] justify-between items-start px-2 py-6;
}

.team_member_position {
    @apply text-[#929292] font-inter text-sm leading-[16px];
}

.team_member_name {
    @apply text-[#0f172a] font-inter text-[15px] leading-6 font-semibold;
}

.more_button {
    @apply px-12 py-5 rounded-[48px] text-white font-inter text-xl font-semibold leading-6 tracking-[0.5px];
    background: linear-gradient(90deg, rgb(255, 137, 180) 0%, rgb(224, 100, 255) 100%);
}