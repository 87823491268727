div {
  box-sizing: border-box;
}

.RoadmapContent {
  width: 100%;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
}

.Roadmap {
  position: relative;
  padding-top: 285px;
  padding-bottom: 105px;
}

.Roadmap h3 {
  text-align: center;
  font-family: Inter;
  font-size: 43px;
  font-weight: 700;
  line-height: 110%;
  color: rgb(65, 81, 98);
  margin-bottom: 30px;
}

.content {
  margin-left: -39px;
  margin-right: -39px;
  border-radius: 70px;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(28, 23, 37);
  padding: 70px 31px 70px 47px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.top-bar {
  display: grid;
  grid-template-columns: 65.22fr 235.78fr 199fr 260.87fr 238.29fr;
  gap: 16px;
  position: relative;
  z-index: 1;
}

.top-bar-first-part {
  position: relative;
  border: 0.5px solid rgba(255, 255, 255, 0.26);
  background: rgb(35, 141, 239);
  display: flex;
  width: 78px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bar-card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  border-radius: 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.33);
  background: rgb(58, 51, 71);
  backdrop-filter: blur(36.15px);
  position: relative;
  z-index: 1;
  order: 0;
}

.rightArrowimg {
  left: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.rightArrowimgQ3 {
  left: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.rightArrowimgQ4 {
  left: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.bar-card-title {
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(255, 255, 255);
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
}

.card-text {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(255, 255, 255);
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
}

.down-bar {
  display: grid;
  grid-template-columns: 260.87fr 234.11fr 224.08fr 216.55fr 63.53fr;
  gap: 16px;
  position: relative;
  z-index: 1;
}

.leftArrow {
  right: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.leftArrowQ3 {
  right: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.leftArrowQ4 {
  right: -28px;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  display: block;
}

.down-bar-first-part {
  position: relative;
  border: 0.5px solid rgba(255, 255, 255, 0.26);
  background: rgb(35, 141, 239);
  display: flex;
  width: 78px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.downArrow {
  top: -33px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 10;
  left: 42%;
  position: absolute;
  width: 40px;
  height: 40px;
  display: block;
  z-index: 10;
}

.card img {
  width: 20px;
  height: 20px;
}

.top-bar-downArrow {
  left: 197%;
  position: absolute;
  top: calc(50% - 132px);
  width: 40px;
  height: 40px;
  z-index: 10;
  display: none;
}

.down-bar-downArrow {
  left: 197%;
  position: absolute;
  top: calc(50% - 132px);
  width: 40px;
  height: 40px;
  z-index: 10;
  display: none;
}

@media (max-width: 1420px) {
  .content {
    border-radius: 50px;
    width: 100%;
    max-width: 882px;
    padding: 35px 26px;
    gap: 47px;
    margin: auto;
  }

  .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
  }

  .top-bar-first-part {
    width: 148px;
    height: 166px;
    font-size: 40px;
  }

  .bar-card {
    min-width: 281px;
  }

  .rightArrowimg {
    left: -46px;
  }

  .down-bar {
    display: flex;
    flex-flow: row-reverse wrap-reverse;
    justify-content: center;
    gap: 50px;
  }

  .down-bar-first-part {
    width: 148px;
    height: 166px;
    font-size: 40px;
  }

  .downArrow {
    top: -46px;
    right: 0px;
    left: unset;
  }

  .top-bar-downArrow {
    display: block;
  }

  .rightArrowimgQ3 {
    display: none;
  }

  .rightArrowimgQ4 {
    transform: scaleX(-1);
    left: -44px;
  }

  .leftArrow {
    right: 0px;
    left: -46px;
    transform: scaleX(-1);
  }

  .down-bar-downArrow {
    display: block;
    top: calc(181% - 132px);
    left: 42%;
  }

  .leftArrowQ3 {
    display: none;
  }

  .leftArrowQ4 {
    left: -45px;
    right: 0px;
  }
}

@media (max-width: 942px) {
  .content {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
  }

  .top-bar {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .down-bar {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    margin: 0 auto;
  }

  .top-bar-first-part {
    width: 100%;
    margin: 0 auto;
  }
  
  .down-bar-first-part {
    width: 100%;
    margin: 0 auto;
  }

  [class*="Arrow"] {
    display: block;
    left: 50%;
    top: -45px;
    transform: translateX(-50%);
  }

  [class*="leftArrow"] {
    transform: translateX(-50%) rotate(-90deg);
  }
  
  [class*="rightArrow"] {
    transform: translateX(-50%) rotate(90deg);
  }
}
