@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham/GothamBook.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham/GothamLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham/GothamMedium_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}